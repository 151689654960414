<template>
  <el-scrollbar class="elv-landing-apply-container">
    <div class="elv-landing-apply-header">
      <p class="elv-landing-apply-header__title">{{ t('report.welcomeUser', { userName: user?.name }) }}</p>
      <p class="elv-landing-apply-header__info">{{ t('message.createFreeProjectTipInfo') }}</p>
    </div>
    <ProjectSettingForm planType="FREE" :showFormList="showFormList" @onCreateProjectSuccess="onCreateProjectSuccess" />
  </el-scrollbar>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { isMobile } from '@/lib/utils'
import { useGlobalStore } from '@/stores/modules/global'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import ProjectSettingForm from '@/components/Project/ProjectSettingForm.vue'
import { ProjectPermissionLevel } from '#/ProjectTypes'

const { t } = useI18n()
const router = useRouter()
const globalStore = useGlobalStore()
const userGlobalStore = useUserGlobalStore()
const menuGlobalStore = useMenuGlobalStore()
const { user } = storeToRefs(userGlobalStore)

const contentWidth = computed(() => {
  return isMobile() || !menuGlobalStore.isFixedMenu ? 'calc(100vw - 48px)' : 'calc(100vw - 179px)'
})

const showFormList = [
  'name',
  'firstName',
  'lastName',
  'companyName',
  'employees',
  'website',
  'jobTitle',
  'email',
  'region'
]

/**
 * 创建免费项目完成后操作
 */
const onCreateProjectSuccess = () => {
  router.push({ name: 'landing-activate' })
}

onMounted(() => {
  const freeProject = globalStore.projectList.find((item) => item.plan === ProjectPermissionLevel.FREE)
  if (freeProject) {
    router.push({ path: '/' })
  }
})
</script>

<style lang="scss" scoped>
.elv-landing-apply-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: v-bind('contentWidth');
  box-sizing: border-box;
  position: relative;
  padding-top: 80px;

  :deep(.el-scrollbar__wrap) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .el-scrollbar__view {
      height: 100%;
    }
  }
}

.elv-landing-apply-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.elv-landing-apply-header {
  display: flex;
  flex-direction: column;

  .elv-landing-apply-header__title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 25px;
    color: #4e515a;
  }

  .elv-landing-apply-header__info {
    width: 574px;
    margin-top: 20px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #636b75;
  }

  .elv-landing-apply-header__cancel {
    cursor: pointer;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #636b75;
    margin-top: 54px;
  }
}

.elv-apply-confirm-header__title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1e2024;
  text-align: center;
  word-break: break-word;
}
</style>
